import {EThemeName} from "ui-components/dist/ThemeProvider";

import {
  IDynaCMSArticleAddress,
  IDynaCMSArticleBlockAddress,
} from "server-app";

import {
  EProgressIconType,
  setDefaultProgressIcon,
} from "ui-components/dist/ProgressIcon";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "./appVersion";

import {EDynaAdOrientation} from "../../dyna-ads/interfaces";

export interface IAppConfig {
  // Basics
  company: ECompany;
  mode: EAppMode;

  appName: string;
  appDescription: string;   // Should be 45chars!

  lightTheme: EThemeName;
  darkTheme: EThemeName;
  progressIconType: EProgressIconType;

  appVersion: string;
  appCodeName: string;
  companyId: string;        // For which client company this app working on
  webBaseUrl: string;
  apiBaseUrl: string;
  googleMapsApiKey: string;
  defaultLanguageId: string;
  pageWidth: number;
  builtAt: string;
  workerActive: boolean;

  // Pages
  noteFoundImage?: IDynaCMSArticleBlockAddress;
  signInPageImage?: IDynaCMSArticleBlockAddress;
  termsOfUseArticles: IDynaCMSArticleAddress[];     // Multi language article
  aboutArticles: IDynaCMSArticleAddress[];          // Multi language article
  contactArticles: IDynaCMSArticleAddress[];        // Multi language article

  // Sentry
  sentryIoDsn: string;

  // Google Analytics
  gaTrackingId?: string;

  // Ad providers
  googleAdSense?: IGoogleAdSenseConfig;
  monetag?: {               // Needs also script in html body, checkout the #1083504624 places
    multiTagScript?: string;
    plainTag?: string;
  };
  adsterra?: IAdsterraConfig;
}

interface IAdsterraConfig {
  squareBannerIFrameScript?: string;
  landscapeBannerIFrameScript?: string;
  portraitBannerIFrameScript?: string;
}

interface IGoogleAdSenseConfig {         // Needs also script in html body, checkout the #1083504624 places
  googleAdId: string;     // The id should be applied also wherever the <!-- GoogleAdSense --> script is loaded
  orientationSetup: Record<EDynaAdOrientation, { dataAdSlot: string }>;
}

const host = window.location.host;

export enum EAppMode {
  DEVELOPMENT = "DEVELOPMENT",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION",
}

export enum ECompany {
  ADORE_GR = "ADORE_GR",
  NET_FASHION = "NET_FASHION",
  THE_GIRL_GUNS = "THE_GIRL_GUNS",
  UNKNOWN = "UNKNOWN",
}

// DCMS-00599 Norton blocks us completely due to Adsterra ads
// Temporary (any maybe permanently) disabled due to DCMS-00599 issue.
const adoreAdsterraConfigActualConfig: IAdsterraConfig = {
  squareBannerIFrameScript: '<iframe src="//www.profitabledisplaynetwork.com/watchnew?key=02543b2e82cc6f4b3db9994553a0bd9a" width="300" height="250" frameborder="0" scrolling="no"></iframe>',
  landscapeBannerIFrameScript: '<iframe src="//www.profitabledisplaynetwork.com/watchnew?key=67c873b6fb2867e80e8ca1e4603f144f" width="728" height="90" frameborder="0" scrolling="no"></iframe>',
  portraitBannerIFrameScript: '<iframe src="//www.profitabledisplaynetwork.com/watchnew?key=7c749334208e945d501ba34926327961" width="160" height="300" frameborder="0" scrolling="no"></iframe>',
};
adoreAdsterraConfigActualConfig;
const adoreAdsterraConfig = undefined;

// Adore google adsense
const adoreGoogleAdSenseConfig: IGoogleAdSenseConfig = {
  googleAdId: 'ca-pub-6486460928215437',
  orientationSetup: {
    [EDynaAdOrientation.AUTO]: {dataAdSlot: '3675801375'},
    [EDynaAdOrientation.HORIZONTAL]: {dataAdSlot: '6092642770'},
    [EDynaAdOrientation.VERTICAL]: {dataAdSlot: '6301964715'},
    [EDynaAdOrientation.RECTANGLE]: {dataAdSlot: '3675801375'},
  },
};

// Note: This is the main source of the client's config, there are no env vars on the client side.
const appConfigs: Record<string, IAppConfig> = {
  // Similar configuration should have the packages/server-app/env_variables.sh
  'www.adore.gr': {
    company: ECompany.ADORE_GR,
    mode: EAppMode.PRODUCTION,
    appName: 'adore.gr',
    appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    appVersion,
    appCodeName,
    companyId: 'adore-gr',
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g",      // Google Maps key is website referrer restricted
    defaultLanguageId: 'el',
    pageWidth: 1128,
    builtAt,
    workerActive: true,
    sentryIoDsn: "https://0f3d0df7432879dfca32e9d441291f8a@o4506004075905024.ingest.sentry.io/4506066751586304",
    googleAdSense: adoreGoogleAdSenseConfig,
    noteFoundImage: {
      groupId: 'internal',
      countryId: 'gr',
      languageId: 'el',
      articleId: '6dbd79b9-2f848f9107e04117ae',
      blockId: 'b1c7139642f84906b2e3293ba84',
    },
    signInPageImage: {
      groupId: 'internal',
      countryId: 'gr',
      languageId: 'el',
      articleId: '6dbd79b9-2f848f9107e04117ae',
      blockId: 'b2633aa1a2f848f9108849c140f',
    },
    termsOfUseArticles: [{
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '49ae1d73-3c48e311e2af026a03',
    }],
    aboutArticles: [{
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '45041890-2f848e4cebf7bb14e4',
    }],
    contactArticles: [
      {
        groupId: 'page',
        countryId: 'gr',
        languageId: 'el',
        articleId: '2ff1f666-2f8490e2dddf5e1bcf',
      },
    ],
    gaTrackingId: 'G-0M2K5D3FB0',
    adsterra: adoreAdsterraConfig,
  },
  'adore.gr': {
    company: ECompany.ADORE_GR,
    mode: EAppMode.PRODUCTION,
    appName: 'adore.gr',
    appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    appVersion,
    appCodeName,
    companyId: 'adore-gr',
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g",      // Google Maps key is website referrer restricted
    defaultLanguageId: 'el',
    pageWidth: 1128,
    builtAt,
    workerActive: true,
    sentryIoDsn: "https://0f3d0df7432879dfca32e9d441291f8a@o4506004075905024.ingest.sentry.io/4506066751586304",
    googleAdSense: adoreGoogleAdSenseConfig,
    noteFoundImage: {
      groupId: 'internal',
      countryId: 'gr',
      languageId: 'el',
      articleId: '6dbd79b9-2f848f9107e04117ae',
      blockId: 'b1c7139642f84906b2e3293ba84',
    },
    signInPageImage: {
      groupId: 'internal',
      countryId: 'gr',
      languageId: 'el',
      articleId: '6dbd79b9-2f848f9107e04117ae',
      blockId: 'b2633aa1a2f848f9108849c140f',
    },
    termsOfUseArticles: [{
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '49ae1d73-3c48e311e2af026a03',
    }],
    aboutArticles: [{
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '45041890-2f848e4cebf7bb14e4',
    }],
    contactArticles: [
      {
        groupId: 'page',
        countryId: 'gr',
        languageId: 'el',
        articleId: '2ff1f666-2f8490e2dddf5e1bcf',
      },
    ],
    gaTrackingId: 'G-0M2K5D3FB0',
    adsterra: adoreAdsterraConfig,
  },
  'staging.adore.gr': {
    company: ECompany.ADORE_GR,
    mode: EAppMode.STAGING,
    appName: 'STG adore.gr',
    appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
    appVersion,
    appCodeName,
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'adore-gr',
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
    defaultLanguageId: 'el',
    pageWidth: 1128,
    builtAt,
    workerActive: true,
    sentryIoDsn: "",
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
    googleAdSense: adoreGoogleAdSenseConfig,
    adsterra: adoreAdsterraConfig,
  },
  'localhost:3630': {
    company: ECompany.ADORE_GR,
    mode: EAppMode.DEVELOPMENT,
    appName: 'DEV adore.gr',
    appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
    appVersion,
    appCodeName,
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'adore-gr',
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
    googleMapsApiKey: "AIzaSyCrNz-MoXU9NT2mhN1PvoLy2R6rGdKSf7Y", // Google Maps key is ip restricted
    defaultLanguageId: 'el',
    pageWidth: 1128,
    builtAt,
    workerActive: false,
    sentryIoDsn: "", // For debugging only: "https://07dc1cf28b44028e629920339c4a8d25@o4506004075905024.ingest.sentry.io/4506066754994176",
    noteFoundImage: {
      // Todo: Note: this is of production!
      groupId: 'internal',
      countryId: 'gr',
      languageId: 'el',
      articleId: '6dbd79b9-2f848f9107e04117ae',
      blockId: 'b1c7139642f84906b2e3293ba84',
    },
    signInPageImage: {
      // Todo: Note: this is of production!
      groupId: 'internal',
      countryId: 'gr',
      languageId: 'el',
      articleId: '6dbd79b9-2f848f9107e04117ae',
      blockId: 'b2633aa1a2f848f9108849c140f',
    },
    termsOfUseArticles: [{
      // Todo: Note: this is of production!
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '49ae1d73-3c48e311e2af026a03',
    }],
    aboutArticles: [{
      // Todo: Note: this is of production!
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '45041890-2f848e4cebf7bb14e4',
    }],
    contactArticles: [
      // Todo: Note: this is of production!
      {
        groupId: 'page',
        countryId: 'gr',
        languageId: 'el',
        articleId: '2ff1f666-2f8490e2dddf5e1bcf',
      },
    ],
    gaTrackingId: '', // No, do not track the dev env statistics
    googleAdSense: adoreGoogleAdSenseConfig,
    // This is Monetag: {plainTag: `<script>(function(s,u,z,p){s.src=u,s.setAttribute('data-zone',z),p.appendChild(s);})(document.createElement('script'),'https://inklinkor.com/tag.min.js',5682445,document.body||document.documentElement)</script>`},
    adsterra: adoreAdsterraConfig,
  },

  'www.thegirlguns.com': {
    company: ECompany.THE_GIRL_GUNS,
    mode: EAppMode.PRODUCTION,
    appName: 'The Girl Guns',
    appDescription: 'Girls portal, Fashion, Beauty, Sex & Lifestyle',
    appVersion,
    appCodeName,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'tgg-com',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    sentryIoDsn: "",
    workerActive: true,
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  },
  'staging.thegirlguns.com': {
    company: ECompany.THE_GIRL_GUNS,
    mode: EAppMode.STAGING,
    appName: 'STG The Girl Guns',
    appDescription: 'Girls portal, Fashion, Beauty, Sex & Lifestyle',
    appVersion,
    appCodeName,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'tgg-com',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    workerActive: true,
    sentryIoDsn: "",
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  },
  'localhost:3640': {
    company: ECompany.THE_GIRL_GUNS,
    mode: EAppMode.DEVELOPMENT,
    appName: 'DEV The Girl Guns',
    appDescription: 'Girls portal, Fashion, Beauty, Sex & Lifestyle',
    appVersion,
    appCodeName,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'tgg-com',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
    googleMapsApiKey: "AIzaSyCrNz-MoXU9NT2mhN1PvoLy2R6rGdKSf7Y", // Google Maps key is ip restricted
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    workerActive: false,
    sentryIoDsn: "",
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  },

  'www.net.fashion': {
    company: ECompany.NET_FASHION,
    mode: EAppMode.PRODUCTION,
    appName: 'net.fashion',
    appDescription: 'Fashion portal - Beauty, Deco, Celebs & Lifestyle',
    appVersion,
    appCodeName,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'net-fashion',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    workerActive: true,
    sentryIoDsn: "",
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  },
  'staging.net.fashion': {
    company: ECompany.NET_FASHION,
    mode: EAppMode.STAGING,
    appName: 'STG net.fashion',
    appDescription: 'Fashion portal - Beauty, Deco, Celebs & Lifestyle',
    appVersion,
    appCodeName,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'net-fashion',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    workerActive: true,
    sentryIoDsn: "",
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  },
  'localhost:3620': {
    company: ECompany.NET_FASHION,
    mode: EAppMode.DEVELOPMENT,
    appName: 'DEV net.fashion',
    appDescription: 'Fashion portal - Beauty, Deco, Celebs & Lifestyle',
    appVersion,
    appCodeName,
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'net-fashion',
    lightTheme: EThemeName.FASHIONX_LIGHT,
    darkTheme: EThemeName.FASHIONX_DARK,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
    sentryIoDsn: "",
    googleMapsApiKey: "AIzaSyCrNz-MoXU9NT2mhN1PvoLy2R6rGdKSf7Y", // Google Maps key is ip restricted
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    workerActive: false,
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  },
};

export const appConfig: IAppConfig =
  appConfigs[host]
  || {
    appName: 'Unknown Company - Host not supported!',
    mode: EAppMode.DEVELOPMENT,
    appVersion,
    appCodeName,
    company: ECompany.UNKNOWN,
    appDescription: 'Unknown Company - Unknown description',
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'unknown-company',
    lightTheme: EThemeName.MUI5_LIGHT,
    darkTheme: EThemeName.MUI5_DARK,
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    sentryIoDsn: "",
    googleMapsApiKey: "",
    defaultLanguageId: 'en',
    pageWidth: 1128,
    builtAt,
    workerActive: false,
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  } as IAppConfig;

setDefaultProgressIcon(appConfig.progressIconType);
